import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm } from '../utils/typography'

function About() {
  return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(1),
            }}
          >
            <p>
              I have an unlimited amount of ideas and limited time. 
            </p>
          </div>
        )
      }

export default About
